import { Footer } from 'components/Footer';
import { CloudExclamation, Hourglass, SadFace, Target } from 'components/Icons';
import { FilePen } from 'components/Icons/FilePen';
import { renderStrategy } from '..';
import {
  Container,
  Content,
  Message,
  Title
} from './styles';

interface IGenericProps {
  title: string;
  message?: string;
  pageId?: renderStrategy | null;
}

export const GenericError = ({
  title,
  message,
  pageId,
}: IGenericProps) => {
  const renderIcon = () => {
    switch (pageId) {
      case renderStrategy.NotFound:
        return <SadFace className='error-icon' />;
      case renderStrategy.LinkExpirado:
        return <Hourglass className='error-icon' />;
      case renderStrategy.ColetasExcedidas:
        return <Target className='error-icon' />;
      case renderStrategy.Draft:
        return <FilePen className='error-icon' />;
      default:
        return <CloudExclamation className='error-icon' />;
    }
  };
  
  return (
    <Container>
      <Content>
        {renderIcon()}
        <Title>{title}</Title>
        <Message dangerouslySetInnerHTML={{ __html: message ?? '' }} />
      </Content>
      <Footer />
    </Container>
  );
};
