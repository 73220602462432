import React from 'react';
import { ReactComponent as FilePenSVG } from '../../assets/icons/file-pen.svg';

const FilePen: React.FC<{ className?: string }> = ({
  className,
}: {
  className?: string;
}) => {
  return <FilePenSVG className={className} />;
};

export { FilePen };
