import {
  DefaultManipuladorPesquisa,
  ManipuladorPesquisa,
} from 'core/pesquisa/ManipuladorPesquisa';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import { getPesquisas } from 'services/pesquisa';

interface CoreContextData {
  manipuladorPesquisa: ManipuladorPesquisa;
  tema: string;
  logo: string;
  error: ErrorInterface;
  surveyStatus: string;
  setErrorState: Dispatch<SetStateAction<ErrorInterface>>;
  dadosProntos: boolean;
  token: string;
  handleSetNewToken: (token: string) => void;
  handleSetErrors: (erros: string[]) => void;
  errosValidationQuestions: string[];
  loading: boolean;
  handleSetLoading: (newLoad: boolean) => void
}

interface ManipuladorPesquisaContext {
  dadosProntos: boolean;
  manipuladorPesquisa: ManipuladorPesquisa;
}

interface ErrorInterface {
  status: number;
  message: string;
  hasError: boolean;
  loading: boolean;
}
// TODO criação de erro específico quando for integrar com o back

const CoreContext = createContext<CoreContextData>({} as CoreContextData);

export const CoreProvider = ({ children }) => {
  const [token, setToken] = useState<string>('');
  const [theme, setTheme] = useState<string>('default');
  const [surveyStatus, setSurveyStatus] = useState<string>('');
  const [contractorImage, setContractorImage] = useState<string>('');
  // estado para guardar o loading da aplicação
  const [loading, setLoading] = useState<boolean>(false);

  const [errosValidationQuestions, setErrosValidationQuestions] = useState<
    string[]
  >([]);

  const [errorState, setErrorState] = useState<ErrorInterface>({
    status: 0,
    message: '',
    hasError: false,
    loading: true,
  });

  // função responsável para atualizar o loading da aplicação
  const handleSetLoading = (newLoad: boolean) => {
    setLoading(newLoad);
  };

  const handleSetNewToken = (newToken: string) => {
    setToken(newToken);
  };

  const handleSetErrors = (newErros: string[]) => {
    setErrosValidationQuestions(newErros);
  };

  const [manipuladorPesquisaContext, setManipuladorPesquisaContext] =
    useState<ManipuladorPesquisaContext>({
      dadosProntos: false,
      manipuladorPesquisa: {} as ManipuladorPesquisa,
    } as ManipuladorPesquisaContext);

  useEffect(() => {
    if (!token) {
      setErrorState({
        status: 404,
        message: 'O token passado expirou.',
        hasError: true,
        loading: true,
      });
    } else {
      getPesquisas(token)
        .then(p => {
          setSurveyStatus(p?.data.status);
          if (p?.status === 202) {
            setErrorState({
              status: p.status,
              message: 'O token passado expirou.',
              hasError: true,
              loading: false,
            });
            window.top?.postMessage(
              {
                fromTensai: true,
                failed: true,
                failure: {
                  status: p.status,
                  message: 'O token passado expirou.',
                },
              },
              '*',
            );
            return;
          }
          setContractorImage(p.data.customizacaoContratante.imagem?.logotipo_light);
          setTheme(p.data.customizacaoContratante.tema); // recupera o tema
          setManipuladorPesquisaContext({
            dadosProntos: true,
            manipuladorPesquisa: new DefaultManipuladorPesquisa(p.data),
          });

          setErrorState({
            status: p.status,
            message: 'Pesquisa pronta.',
            hasError: false,
            loading: false,
          });
        })
        .catch(err => {
          setErrorState({
            status: err.response.status,
            message: err.response.data,
            hasError: true,
            loading: false,
          });
          window.top?.postMessage(
            {
              fromTensai: true,
              failed: true,
              failure: {
                status: err.response.status,
                message: err.response.data,
              },
            },
            '*',
          );
        });
    }
  }, [token]);

  return (
    <CoreContext.Provider
      value={{
        manipuladorPesquisa: manipuladorPesquisaContext.manipuladorPesquisa,
        tema: theme,
        logo: contractorImage,
        error: errorState,
        surveyStatus,
        dadosProntos: manipuladorPesquisaContext.dadosProntos,
        token,
        setErrorState,
        handleSetNewToken,
        handleSetErrors,
        errosValidationQuestions,
        loading,
        handleSetLoading,
      }}
    >
      {children}
    </CoreContext.Provider>
  );
};

export default CoreContext;
