import { Alternativa, Pergunta, TipoAlternativa } from 'core/pesquisa/Pesquisa';
import {
  AlternativaAbertaNumeroSelecionada,
  AlternativaAbertaTextoSelecionada,
  AlternativaSelecionada,
} from 'core/pesquisa/resposta/pergunta/aternativa/RespostaAlternativa';
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { AlternativaFechada } from 'sections/Question/Alternativas/AlternativaFechada';
import { AlternativaAberta } from '../Alternativas/AlternativaAberta';
import { AlternativaAbertaOrdemSelecao } from '../Alternativas/AlternativaAbertaOrdemSelecao';
import { AlternativaOrdemSelecao } from '../Alternativas/AlternativaOrdemSelecao';
import { PerguntaRef, genericRespostaAtual } from '../interfaces';

interface Props {
  pergunta: Pergunta;
  idSecao: number;
  className: string;
  ordemCitacao: boolean;
  respostaAtual: (id: number) => genericRespostaAtual;
}

const PerguntaMultiplaReferenciable: ForwardRefRenderFunction<
  PerguntaRef,
  Props
> = (props, ref) => {
  const {
    pergunta,
    idSecao,
    className,
    ordemCitacao = false,
    respostaAtual,
  } = props;

  const questionState = {
    ...pergunta,
    alternativas: pergunta.alternativas ? pergunta.alternativas : [],
  };

  const [alternativasSelecionadas, setAlternativasSelecionadas] = useState<
    AlternativaSelecionada[]
  >([]);
  const [idDestinoPulo, setIdDestinoPulo] = useState(0);

  useImperativeHandle(ref, () => ({
    alternativas: alternativasSelecionadas,
    data: null,
    horario: null,
    texto: null,
    foto: null,
    alternativas_selecionadas: null,
    destinoPulo: idDestinoPulo,
    vazia: alternativasSelecionadas.length === 0,
  }));

  const buildRespostaAlternativa = (selectedOptionId: number) => {
    const alternativaAtual = questionState.alternativas.find(
      alt => alt.id === selectedOptionId,
    );

    if (alternativaAtual) {
      if (alternativaAtual.marcacao?.id_pergunta_destino_pulo) {
        setIdDestinoPulo(alternativaAtual.marcacao?.id_pergunta_destino_pulo);
      }
  
      const maiorOrdem =
        alternativasSelecionadas.length > 0
          ? Math.max(...alternativasSelecionadas.map(alt => alt.ordem_selecao))
          : 0;
  
      const altReturn = {
        id_alternativa: alternativaAtual.id,
        ordem_selecao: maiorOrdem + 1,
        tipo_alternativa: alternativaAtual.tipo_alternativa,
        comportamento: '',
      };

      return altReturn;
    }
    return null;
  };

  // Consome o estado local armazenado na ultima ação de próximo/voltar
  // em uma pergunta
  useEffect(() => {
    const respostaLocalAtual = respostaAtual(pergunta.id);

    if (respostaLocalAtual) {
      setAlternativasSelecionadas(respostaLocalAtual.estadoAtual);
    }
  }, [pergunta.id, respostaAtual]);

  /**
   * Verifica se a quantidade de alternativas atualmente selecionadas
   * é maior do que o permitido e retorna um booleano.
   */
  const withCheckedItemsOverflow = useCallback(() => {
    return (
      alternativasSelecionadas.length >=
      (pergunta.definicoes?.numero_maximo_respostas ?? 1)
    );
  }, [alternativasSelecionadas, pergunta]);

  // TODO refatorar para ser reutilizado em outros componentes
  const onChangeCallback = evt => {
    const selOptId = Number(evt.value);

    const alternativaSelecionada = alternativasSelecionadas.findIndex(
      selected => selected.id_alternativa === selOptId,
    );

    if (alternativaSelecionada === -1 && withCheckedItemsOverflow()) {
      return null;
    }

    const altBuild = buildRespostaAlternativa(selOptId);
    const { checked } = evt;

    if (altBuild) {
      if (checked) {
        const alts = [...alternativasSelecionadas, altBuild];
        setAlternativasSelecionadas(alts);
      } else {
        const ordemAtual = alternativasSelecionadas.find(
          alt => alt.id_alternativa === selOptId,
        )?.ordem_selecao;
        if (ordemAtual === undefined) {
          return null;
        }

        const alts = alternativasSelecionadas
          .filter(alt => alt.id_alternativa !== selOptId)
          .map(alt => ({
            ...alt,
            ordem_selecao:
              alt.ordem_selecao > ordemAtual
                ? alt.ordem_selecao - 1
                : alt.ordem_selecao,
          }));

        setAlternativasSelecionadas(alts);
      }
    }
    return null;
  };

  const onChangeTextoAberta = (
    value: string,
    alternativa: AlternativaSelecionada,
  ) => {
    if (alternativa.tipo_alternativa === TipoAlternativa.ABERTA_TEXTO) {
      const novasAlternativas = alternativasSelecionadas.map(atual =>
        atual.id_alternativa === alternativa.id_alternativa
          ? ({
              ...alternativa,
              texto: value,
              comportamento:
                value.length === 0 ? '' : alternativa.comportamento,
            } as AlternativaAbertaTextoSelecionada)
          : atual,
      );
      setAlternativasSelecionadas(novasAlternativas);
    } else {
      const novasAlternativas = alternativasSelecionadas.map(atual =>
        atual.id_alternativa === alternativa.id_alternativa
          ? ({
              ...alternativa,
              numero: value.length === 0 ? '' : Number(value),
              comportamento:
                value.length === 0 ? '' : alternativa.comportamento,
            } as AlternativaAbertaNumeroSelecionada)
          : atual,
      );
      setAlternativasSelecionadas(novasAlternativas);
    }
  };
  const renderAlternativa = (alternativa: Alternativa) => {
    const alternativaAtual = alternativasSelecionadas.find(
      alt => alt.id_alternativa === alternativa.id,
    );

    // TODO: EM ALGUNS MOMENTOS "alternativaAtual" não é reconhecido, e seu valor é UNDEFINED!!!
    const isNumericAlternative = alternativaAtual
      ? alternativaAtual.tipo_alternativa === TipoAlternativa.ABERTA_NUMERO
      : alternativa.tipo_alternativa === TipoAlternativa.ABERTA_NUMERO;

    if (!ordemCitacao) {
      if (alternativa.tipo_alternativa === TipoAlternativa.FECHADA) {
        return (
          <div key={`${idSecao}-${alternativa.id}`} className="">
            <AlternativaFechada
              key={`${idSecao}-${alternativa.id}`}
              alternativa={{
                ...alternativa,
                ordem: alternativaAtual ? alternativaAtual?.ordem_selecao : 0,
              }}
              onChange={onChangeCallback}
              checked={
                !!alternativasSelecionadas.find(
                  alt => alt.id_alternativa === alternativa.id,
                )
              }
              tipoPergunta={pergunta.tipo_pergunta}
            />
          </div>
        );
      }

      const respostaLocalAtual = respostaAtual(pergunta.id);
      const respostaAlternativaAberta =
        respostaLocalAtual &&
        respostaLocalAtual.estadoAtual.find(
          resposta => resposta.id_alternativa === alternativa.id,
        );

      const respostaAlternativaAbertaPorTipo =
        respostaAlternativaAberta &&
        alternativa.tipo_alternativa === 'ABERTA_NUMERO'
          ? respostaAlternativaAberta.numero
          : respostaAlternativaAberta && respostaAlternativaAberta.texto;
      return (
        <div className="" key={`${idSecao}-${alternativa.id}`}>
          <AlternativaAberta
            key={`${idSecao}-${alternativa.id}`}
            alternativa={{
              ...alternativa,
              ordem: alternativaAtual ? alternativaAtual?.ordem_selecao : 0,
            }}
            onChange={onChangeCallback}
            onChangeTexto={onChangeTextoAberta}
            checked={
              !!alternativasSelecionadas.find(
                alt => alt.id_alternativa === alternativa.id,
              )
            }
            disabled={withCheckedItemsOverflow()}
            valorAnterior={respostaAlternativaAbertaPorTipo}
            abertaNumero={isNumericAlternative}
            tipoPergunta={pergunta.tipo_pergunta}
          />
        </div>
      );
    }
    if (alternativa.tipo_alternativa === TipoAlternativa.FECHADA) {
      return (
        <div key={`${idSecao}-${alternativa.id}`} className="h-28p">
          <AlternativaOrdemSelecao
            key={`${idSecao}-${alternativa.id}`}
            alternativa={{
              ...alternativa,
              ordem: alternativaAtual ? alternativaAtual?.ordem_selecao : 0,
            }}
            onChange={onChangeCallback}
            checked={!!alternativaAtual}
          />
        </div>
      );
    }

    return (
      <div key={`${idSecao}-${alternativa.id}`} className=" h-28p">
        <AlternativaAbertaOrdemSelecao
          key={`${idSecao}-${alternativa.id}`}
          alternativa={{
            ...alternativa,
            ordem: alternativaAtual ? alternativaAtual?.ordem_selecao : 0,
          }}
          onChange={onChangeCallback}
          onChangeTexto={onChangeTextoAberta}
          checked={
            !!alternativasSelecionadas.find(
              alt => alt.id_alternativa === alternativa.id,
            )
          }
          abertaNumero={
            alternativaAtual?.tipo_alternativa === TipoAlternativa.ABERTA_NUMERO
          }
        />
      </div>
    );

    return null;
  };
  return (
    <div className={`${className} flex flex-col`}>
      <div className="flex flex-col gap-y-6">
        {questionState.alternativas.map(alternativa =>
          renderAlternativa(alternativa),
        )}
      </div>
    </div>
  );
};

const PerguntaMultipla = forwardRef(PerguntaMultiplaReferenciable);
export { PerguntaMultipla };
