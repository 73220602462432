import { DraftMessage } from 'components/DraftMessage';
import CoreContext from 'providers/core';
import { useContext, useEffect, useState } from 'react';
import { FinalizarPesquisa } from 'sections/FinalizarPesquisa';
import { GenericError } from './GenericError';
import { Container } from './style';

interface Props {
  statusCode: number;
  surveyStatus: string;
}

interface IHandle400Error {
  title: string;
  message: string;
  icon: renderStrategy | null;
}

export enum renderStrategy {
  NotFound = 'NotFound',
  LinkExpirado = 'LinkExpirado',
  Accepted = 'Accepted',
  ColetasExcedidas = 'ColetasExcedidas',
  Draft = 'Draft',
}

const PaginaDeErro = ({ statusCode, surveyStatus }: Props) => {
  const { logo } = useContext(CoreContext);
  const [error400Object, setError400Object] = useState<IHandle400Error>({
    title: '',
    message: '',
    icon: null
  });

  function handle400Error(): void {
    if (surveyStatus === 'RASCUNHO') {
      setError400Object({
        icon: renderStrategy.Draft,
        title: 'Ainda não estamos recebendo respostas',
        message: 'Para mais informações, entre em contato com o proprietário da avaliação.'
      });
    } else {
      setError400Object({
        icon: null,
        title: 'Notamos uma instabilidade',
        message: 'Calma, guardamos com carinho todas as suas respostas.</br> Recarregue a página em alguns minutos, toda a nossa equipe está trabalhando para normalizar as coisas em breve.',
      });
    }
  }

  useEffect(() => {
    handle400Error();
  }, [surveyStatus]);

  switch (statusCode) {
    case 404:
      return (
        <GenericError
          pageId={renderStrategy.NotFound}
          title="Ops! Algo não aconteceu como esperado"
          message="Página não encontrada.</br>
          Verifique se a URL está correta e recarregue a página.</br>
          Caso o erro persista contate: suporte@tensai.com.br"
        />
      );
    case 422:
      return (
        <GenericError
          title="Ops! Link do formulário expirado"
          message="Este formulário encontra-se expirado.</br> Esta pesquisa não está mais recebendo coletas"
          pageId={renderStrategy.LinkExpirado}
        />
      );
    case 202:
      return (
        <Container>
          <DraftMessage />
          <FinalizarPesquisa logoContratante={logo} isDraft />
        </Container>
      );
    case 419:
      return (
        <GenericError
          title="Coletas Excedidas"
          message="No momento não conseguimos receber mais coletas.</br> A meta estabelecida para esse formulário foi atingida."
          pageId={renderStrategy.ColetasExcedidas}
        />
      );
    case 400:
      return (
        <GenericError
          pageId={error400Object.icon}
          title={error400Object.title}
          message={error400Object.message}
        />
      );
    default:
      return (
        <GenericError
          title="Notamos uma instabilidade"
          message="Calma, guardamos com carinho todas as suas respostas.</br>
          Recarregue a página em alguns minutos, toda a nossa equipe está trabalhando para normalizar as coisas em breve."
        />
      );
  }
};

export { PaginaDeErro };
