import { PaginaDeErro } from 'pages/PaginaDeErro';
import PaginaDeLoading from 'pages/PaginaDeLoading';
import PesquisaPrincipal from 'pages/PaginaPrincipalPesquisa';
import CoreContext from 'providers/core';
import { useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import '../../index.css';
import { Container } from './styles';
import './styles.css';

interface ParamsProps {
  token: string;
}

const Main: React.FC = () => {
  const { error, dadosProntos, surveyStatus, handleSetNewToken } = useContext(CoreContext);

  const { token } = useParams<ParamsProps>();

  const renderPaginaPrincipal = useCallback(() => {
    if (error.loading) return <PaginaDeLoading />;
    if (error.hasError) return <PaginaDeErro statusCode={error.status} surveyStatus={surveyStatus} />;
    return dadosProntos ? <PesquisaPrincipal /> : null;
  }, [dadosProntos, error.hasError, error.status, error.loading, surveyStatus]);

  useEffect(() => {
    handleSetNewToken(token);
  }, [handleSetNewToken, token]);

  return (
    <Container>
      {renderPaginaPrincipal()}
    </Container>
  );
};

export default Main;
